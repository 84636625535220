import React from "react";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { portal_url } from "../root-config";

export default function ProfilePopup({
    handleChange,
    context,
    flex,
    settings,
    clock,
    handleClose
}) {
    return (
        <div className="profile-popup">
            <div className="pop-row">
                <h4>Profile</h4>
                <Button
                    style={{ 
                        background: "transparent", 
                        color: "red" 
                    }}
                    onClick={handleClose}
                >
                    Close
                </Button>
            </div>

            <p className="center-text">
                {context.name} <br/>
                <i className="sub-text">{context.tenant_name}</i>
            </p>

            <div className="center-text">
                <a href={portal_url + "/profile"} target="_blank" className="link">
                    <Button className="link-button" >
                        View Profile
                    </Button>
                </a>
            </div>

            <br/>

            <h4 className="section-title">Attendance</h4>

            {
                settings.flexi_time === 1 && (
                    <p className="center-text">Flexi Balance: <br/> {flex}</p>
                )
            }

            {clock === null ? (
                <Tooltip title={<p>Loading Clock Status, Please Wait</p>}>
                    <Button disabled
                        style={{ background: "grey", color: "white", textAlign: "center" }}
                    >
                        <CircularProgress color="secondary" /> Loading Clock Status...
                    </Button>
                </Tooltip>
            ) : ( clock ? (
                <Tooltip title={<p>Clock out of the Time and Attendance system.</p>}>
                    <Button
                        style={{ background: "blue", color: "white", textAlign: "center" }}
                        onClick={() => handleChange()}
                    >
                        Clock out
                    </Button>
                </Tooltip>
            ) : (
                <Tooltip title={<p>Clock in to the Time and Attendance system.</p>}>
                    <Button
                        text="Clock In"
                        style={{ background: "red", color: "white", textAlign: "center" }}
                        onClick={() => handleChange()}
                    >
                        Clock in
                    </Button>
                </Tooltip>
            ))}
        </div>
    )
}