import React, { useState } from "react";
import {
  Icon,
  IconButton,
  Drawer,
  Box,
  Grid,
  Tooltip
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CottageIcon from "@mui/icons-material/Cottage";
import { NavLink } from "react-router-dom";
import NotificationButton from "./components/notification-button";
import BroadcastButton from "./components/broadcast-button";
import ProfileButton from "./components/profile-button";
import config from "./root-config.js";

import "./styles.css";

export default function Header(props) {
  const context = props.context;
  const portal_url = config.portal_url;

  const [drawer, setDrawer] = useState(false);
  const [anchor, setAnchor] = useState(false);

  const toggleDrawer = (anchor, open) => (e) => {
    if (e.key === "Tab") return;

    setDrawer({ ...drawer, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: "auto",
        height: "100%",
        backgroundColor: context.theme.sidebar.background,
        overflow: "auto",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div style={{ display: "flex", flexDirection: "row", margin: "30px" }}>
        <div style={{ flex: 1 }}>
          <img
            className="profile"
            src={
              typeof context.profile != "undefined"
                ? context.profile
                : "https://www.gravatar.com/avatar/94d093eda664addd6e450d7e9881bcad?s=32&d=identicon&r=PG"
            }
            alt="Profile"
          />
        </div>
        <div className="user-content" style={{ flex: 2 }}>
          <div
            className="username"
            style={{ color: context.theme.sidebar.font }}
          >
            {context.name}
          </div>
          {context.group_name && (
            <div className="group-name">{context.group_name}</div>
          )}
          <div className="role" style={{ color: context.theme.sidebar.font }}>
            {context.role_name}
          </div>
        </div>
      </div>

      <ul
        style={{
          overflowY: "scroll",
          marginRight: "-45px",
          paddingRight: 20,
        }}
      >
        {Object.keys(context.access).map((item, key) => {
          return (
            <li key={key} className="navLink">
              <NavLink activeClassName="nav-active" to={`/${item}/index`}>
                <div
                  className="nav"
                  style={{ color: context.theme.sidebar.font }}
                >
                  <Icon>{context.access[item].options.icon_name}</Icon>
                  <span className="navText">
                    {context.access[item].options.side_menu_name
                      ? context.access[item].options.side_menu_name
                      : item}
                  </span>
                </div>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </Box>
  );

  return (
    <div
      className="row-sm header"
      style={{
        backgroundColor: config.staging
          ? "rgba(255, 193, 7, 1)"
          : context.theme.sidebar.background,
      }}
    >
      <Grid
        container
        spacing={0.5}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item xs={1} md={2}>
          {/* <div className="col-lg-4"> */}
          <span className="">
            <img
              className="logo"
              src={context.logo}
              alt="Company Logo"
              style={{
                width: "auto",
                height: "auto",
                background: "white",
                borderRadius: "5px",
              }}
            />
          </span>
        </Grid>

        {config.staging && (
          <>
            <Grid item xs={0} lg={0.5}>
              <div className="rotatingShape stagingMessage">☢</div>
            </Grid>
            <Grid item xs={0} lg={1}>
              <p className="stagingMessage" style={{ textAlign: "center", fontFamily: "courier" }}>
                <b>Development Build</b>
              </p>
            </Grid>
            <Grid item xs={0} lg={0.5}>
              <div className="rotatingShape stagingMessage">☢</div>
            </Grid>
          </>
        )}

        {/* Spacer element, depending on logo it may not be enforcing it's block width  */}



        <Grid item xs={8} md={6} lg={2}>
          <Grid container spacing={0} justifyContent="flex-end">
            <Grid item xs={2} sm={2} md={2} lg={0} xl={0} className="menuButton">
              <IconButton
                style={{ color: context.theme.sidebar.font }}
                onClick={toggleDrawer(anchor, true)}
              >
                <MenuIcon>{anchor}</MenuIcon>
              </IconButton>
              <Drawer
                elevation={1}
                transitionDuration={1000}
                anchor={"left"}
                open={drawer[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </Grid>
            {/* <div style={{ display: "flex", justifyContent: "right"}}> */}
            {context.attendance_access && (
                <Grid item xs={2}>
                    <ProfileButton context={context} />
                </Grid>
            )}
            <Grid item xs={2}>
                <NotificationButton
                  auth={context}
                  color={context.theme.sidebar.font}
                  channels={props.channels}
                />
            </Grid>
            <Grid item xs={2}>
                <BroadcastButton
                  auth={context}
                  color={context.theme.sidebar.font}
                />
            </Grid>
            <Grid item xs={2}>
              <Tooltip title={<h6>Return to MYBE Portal</h6>}>
                <a href={portal_url}>
                  <IconButton style={{ color: context.theme.sidebar.font }}>
                    <CottageIcon />
                  </IconButton>
                </a>
              </Tooltip>
            </Grid>
          </Grid>
          {/* </div> */}
        </Grid>
      </Grid>
    </div>
  );
}
