import React, { useState, useEffect } from "react";
import { NotificationsApi } from "@unity/components";
import * as config from "../root-config.js";

import IconButton from "@mui/material/IconButton";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotifcationPopup from "./notification-popup";
import { Tooltip } from "@mui/material";
import { Notifications } from "@mui/icons-material";

export default function NotificationButton(props) {
    const [notifications, setNotifications] = useState([]);
    const [anchor, setAnchor] = useState(null);
    const [tooltip, setTooltip] = useState(false);
  
    const getNotifications = async () => {
      const res = await NotificationsApi.getNotifications();
      setNotifications(res.data ?? false);
    };
  
    const handleOpen = (event) => {
      setAnchor(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchor(null);
    };
  
    const handleDismiss = async (notification_id) => {
      const res = await NotificationsApi.markAsRead(notification_id, false);

      const notifs = await NotificationsApi.getNotifications();
      setNotifications(notifs.data);
    };
  
    const handleDismissForAll = async (notification_id) => {
      const res = await NotificationsApi.markAsRead(notification_id, true);

      const notifs = await NotificationsApi.getNotifications();
      setNotifications(notifs.data);
    };
  
    const handleMarkAllRead = async () => {
      const ids = [];
      notifications.map((notif, key) => {
        ids.push(notif.id);
      });

      const res = await NotificationsApi.markManyAsRead(ids);

      const notifs = await NotificationsApi.getNotifications();
      setNotifications(notifs.data);
    };

    const bindEvents = () => {
      if(props.channels['tenant'])
      {
        props.channels['tenant'].bind('notification', (e) => {
          console.log('tenant notif', e);
          getNotifications();
        });
      }

      if(props.channels['user'])
      {
        props.channels['user'].bind('notification', (e) => {
          console.log('user notif', e);
          getNotifications();
        });
      }

      if(props.channels['app'])
      {
        props.channels['app'].bind('notification', (e) => {
          console.log('app notif', e);
          getNotifications();
        });
      }
    }
  
    const open = Boolean(anchor);
    const id = open ? "notification-popover" : undefined;
  
    useEffect(() => {
      getNotifications();
    }, []);

    useEffect(() => {
      bindEvents()
    }, [props.channels]);

    return notifications ? (
        <>
          <Tooltip arrow title={<h6>View notifications</h6>} open={tooltip} onOpen={() => {setTooltip(!open)}} onClose={() => {setTooltip(false)}}>
            <IconButton
                style={{ color: props.color !== undefined ? props.color : "black" }}
                onClick={(event) => {
                    handleOpen(event);
                }}
            >
                <Badge
                    badgeContent={notifications.length}
                    color="error"
                    overlap="circular"
                    invisible={notifications && notifications.length < 1}
                >
                    <NotificationsIcon />
                </Badge>
            </IconButton>
          </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchor}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <NotifcationPopup
                    notifications={notifications}
                    handleDismiss={handleDismiss}
                    handleDismissForAll={handleDismissForAll}
                    handleMarkAllRead={handleMarkAllRead}
                    handleClose={handleClose}
                    auth={props.auth}
                />
            </Popover>
        </>
    ) : (
      <Tooltip title="Failed to Fetch Notifications - Please Refresh">
        <IconButton
          style={{ color: props.color !== undefined ? props.color : "black" }}
          onClick={(event) => {
              handleOpen(event);
          }}
        >
          <Badge
            badgeContent="E"
            color="error"
            overlap="circular"
          >
            <CrisisAlertIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    )
}