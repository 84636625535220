import React, { useEffect, useState } from "react";
import { Popover, IconButton, Tooltip } from "@mui/material";
import {
    AttendanceApi,
} from "@unity/components";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import "./profile-popup-styles.css";
import ProfilePopup from "./profile-popup";

export default function Header({context}) {
  const [settings, setSettings] = useState({});
  const [user, setUser] = useState(false);
  const [clock, setClock] = useState(null);
  const [flex, setFlex] = useState("0:00");
  const [anchor, setAnchor] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  const handleChange = () => {
    navigator.geolocation.getCurrentPosition(getCoords, () => {
      alert("Failed to Clock in/out. It appears that you have rejected location permissions for MYBE Time and Attendance. MYBE requires location data to complete the clock in/out process. Please allow location services to continue.");
    });
  };

  const handleClose = () => {
    setAnchor(null);
  }

  const getCoords = async (pos) => {
    const data = {
      status: !clock === true ? 1 : 0,
      longitude: pos.coords.longitude,
      latitude: pos.coords.latitude,
      agent_id: context.agent_id,
    };

    const res = await AttendanceApi.setClock(data);
    if(res.success === undefined || !res.success || res.statusCode == 500)
    {
      alert("Failed to Update Clock. Please Refresh and Try Again");
      return false;
    }

    window.dispatchEvent(new CustomEvent('clockEventHeader', {
      bubbles: true,
      cancelable: true,
      detail: { status: data.status }
    }));

    const userRes = await AttendanceApi.getFlexBalance();
    setFlex(userRes.data);
    setClock(!clock);
  };

  const getData = async () => {
    setClock(null);
    const res = await AttendanceApi.getSettings();
    setSettings(res.data);
    const userRes = await AttendanceApi.getFlexBalance();
    setFlex(userRes.data);
    const clockRes = await AttendanceApi.getClockStatus();
    setClock(clockRes.data);
  };

  useEffect(() => {
    getData();
    window.addEventListener("clockEventAttend", (event) => {getData()})
  }, []);

  const open = Boolean(anchor);
  const id = open ? "clock-popover" : undefined

  return (
    <>
        <Tooltip arrow title={<h6>View your profile. Clock in and out in MYBE Time and Attendance</h6>} open={tooltip} onOpen={() => {setTooltip(!open)}} onClose={() => {setTooltip(false)}}>
          <IconButton
            style={{ backgroundColor: clock === null ? "grey" : ( clock ? "blue" : "red" ), color: "white" }}
            onClick={ (e) => { setAnchor(e.currentTarget) }}
          >
              <PersonPinIcon/>
          </IconButton>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchor}
          onClose={() => {setAnchor(null)}}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <ProfilePopup
            handleChange={handleChange}
            context={context}
            flex={flex}
            settings={settings}
            clock={clock}
            handleClose={handleClose}
          />
        </Popover>
    </>
  );
}
