import React from "react";
import "./notification-popup-styles.css";

import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';
import Tooltip from "@mui/material/Tooltip";

import { DateHandler } from "@unity/components";

export default function NotifcationPopup({
  notifications,
  handleDismiss,
  handleDismissForAll,
  handleMarkAllRead,
  handleClose,
  auth,
}) {
  return (
    <>
      <div className="notif-box">
        <div className="row">
          <div className="col-lg-6">
            <h4>Notifications</h4>
          </div>
          {notifications && notifications.length > 0 ? (
            <div className="col-lg-6">
              <Button
                style={{ textAlign: "right" }}
                variant="outlined"
                onClick={() => handleMarkAllRead()}
              >
                <MarkChatReadIcon style={{ marginRight: "5px" }} /> Mark All As
                Read
              </Button>
            </div>
          ) : (
            <div className="col-lg-2">
              <Button onClick={() => handleClose()} color="error">
                <HighlightOffIcon style={{ marginRight: "5px" }} /> Close
              </Button>
            </div>
          )}
        </div>
        {notifications && notifications.length > 0 ? (
          notifications.map((notif, key) => {
            var createdAt = new DateHandler( notif.created_at );
            return (
              <div className="notif-item">
                <div className="row">
                  <h6 className="notif-title">{notif.name}</h6>
                </div>
                {notif.description ? (
                  notif.poster === "MYBE" ? (
                    <>
                      <hr />
                      <div dangerouslySetInnerHTML= {{ __html: notif.description }} />
                    </>
                  ) : (
                    <>
                      <hr />
                      <p className="notif-body">{notif.description}</p>
                    </>
                  )
                ) : null}
                <div className="row notif-btn-group">
                  <div className="col-lg-4">
                    <Tooltip title="Dismiss" arrow>
                      <IconButton
                        onClick={() => {
                          handleDismiss(notif.id);
                        }}
                        style={{
                          color: "red",
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Tooltip>
                    {(auth.access.settings.MA && notif.application_uuid == auth.app_uuid) || auth.super_admin ? (
                      <Tooltip title="Dismiss for All" arrow>
                        <IconButton
                          onClick={() => {
                            handleDismissForAll(notif.id);
                          }}
                          style={{
                            color: "red",
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </div>
                  <div className="col-lg-2" />
                  <div className="col-lg-3">
                    {notif.link ? (
                      <div className="notif-link">
                        <a href={`https://${notif.link}`} target="_blank">
                          <Button variant="outlined">
                            {notif.link_action ? notif.link_action : "view"}
                          </Button>
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <i>{createdAt.UTCToLocalVerboseString()}</i>
                  </div>
                  <div className="col-lg-6">
                    <i>From: {notif.poster}</i>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="notif-clear-box">
            <CelebrationTwoToneIcon
              className="notif-clear-icon"
              style={{
                width: "120px",
                height: "120px"
              }}
            />
            <p>All Clear!</p>
          </div>
        )}
      </div>
    </>
  );
}
