import React, { useState, useEffect } from "react";
import { NotificationsApi, PersonnelApi } from "@unity/components";

import IconButton from "@mui/material/IconButton";
import CampaignIcon from '@mui/icons-material/Campaign';
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";

import BroadcastPopup from "./broadcast-popup";

export default function BroadcastButton(props) {
    const [anchor, setAnchor] = useState(null);
    const [message, setMessage] = useState("");
    const [user_uuid, setUserUuid] = useState(0);
    const [users, setUsers] = useState([]);
    const [tooltip, setTooltip] = useState(false);

    const sendBroadcast = async () => {
        const payload = {
            "name": `Message from ${props.auth.name}`,
            "description": message,
            "tenant_uuid": props.auth.line_manager && user_uuid === -1 ? props.auth.tenant_uuid : null,
            "user_uuid": user_uuid === -1 ? null : user_uuid,
            "poster": props.auth.name,
            "channel": user_uuid === -1 && props.auth.line_manager ? props.auth.tenant_uuid : user_uuid,
            "event": "notification",
            "message": "New Broadcast Message Recieved"
        }

        if( !props.auth.line_manager && ( user_uuid === null || user_uuid === 0 ) )
        {
            alert("You must be a line manager to send a group broadcast!");
            return;
        }
        const res = await NotificationsApi.saveNotification(payload);
        if(!res.data)
        {
            alert("Failed to Send Message!");
        }
        else
        {
            handleUserChange(0);
            handleMessageChange("");
            setAnchor(null);
        }
    }

    const handleUserChange = (user_uuid) => {
        setUserUuid(user_uuid);
    }

    const handleMessageChange = (message) => {
        setMessage(message);
    }

    const handleOpen = (event) => {
        setAnchor(event.currentTarget);
    }

    const handleClose = () => {
        setAnchor(null);
    }

    const getUsers = async () => {
        const users = await PersonnelApi.getPersonnel();
        setUsers(users.data);
    }

    const open = Boolean(anchor);
    const id = open ? "notification-popover" : undefined;

    useEffect(() => {
        getUsers()
    }, []);

    return (
        <>
        <Tooltip arrow title={<h6>Send a broadcast to someone in your department</h6>} open={tooltip} onOpen={() => {setTooltip(!open)}} onClose={() => {setTooltip(false)}}>
            <IconButton
                style={{ color: props.color !== undefined ? props.color : "black" }}
                onClick={(event) => {
                    handleOpen(event)
                }}
            >
                <CampaignIcon />
            </IconButton>
        </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchor}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <BroadcastPopup
                    auth={props.auth}
                    users={users}
                    handleUserChange={handleUserChange}
                    handleMessageChange={handleMessageChange}
                    sendBroadcast={sendBroadcast}
                />
            </Popover>
        </>
    )
}