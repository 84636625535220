import React from "react";
import "./broadcast-popup-styles.css";

import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";

export default function BroadcastPopup(props) {
  return (
    <>
      <div className="broadcast-box">
        <div className="row">
          <div className="col-lg-12">
            <h4>Broadcast a Message</h4>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-lg-12">
            <InputLabel>Recipient</InputLabel>
            {props.users ? (
              <>
                <Select
                  label="Recipent"
                  onChange={(e) => props.handleUserChange(e.target.value)}
                  value={props.user_uuid}
                  name="user_uuid"
                  fullWidth={true}
                >
                  {props.auth.line_manager && (
                    <MenuItem value={-1}>Entire Department</MenuItem>
                  )}
                  {props.users.map((user, key) => {
                    return (
                      <MenuItem key={key} value={user.uuid}>
                        {user.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            ) : (
              <p>Loading Users...</p>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-lg-12">
            <TextField
              label="Message"
              type="text"
              multiline
              value={props.message}
              name="message"
              onChange={(e) => props.handleMessageChange(e.target.value)}
              fullWidth={true}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-8" />
          <div className="form-group col-lg-2">
            <Button
              variant="outlined"
              onClick={() => {
                props.sendBroadcast();
              }}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
