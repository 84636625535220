import React, { useEffect, useState } from "react";

import Routes from "./Routes";
import * as config from "./root-config";

export default function Root(props) {
  const Pusher = require("pusher-js");
  const [pusher, setPusher] = useState(
    new Pusher(config.pusherClientId, {
      cluster: config.pusherRegion,
    })
  );

  const [ channels, setChannels ] = useState([]);

  const initChannels = async () => {
    const tempChannels = [];

    tempChannels["tenant"] = await pusher.subscribe(props.auth.tenant_uuid);
    tempChannels["app"] = await pusher.subscribe(props.auth.app_uuid);
    tempChannels["user"] = await pusher.subscribe(props.auth.id);

    setChannels(tempChannels);
  }

  useEffect(() => {
    initChannels();
  }, [])
  return (
      <Routes context={props.auth} channels={channels} />
  );
}