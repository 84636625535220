import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Header from "./Header";

function Routes(props) {
  const context = props.context;
  return (
    <Router>
      <Header context={context} channels={props.channels}></Header>
    </Router>
  );
}

export default Routes;
